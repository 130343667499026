<template>
  <div class="w-100">
    <h5 class="title">{{ title }}</h5>
    <hr>
    <b-form class="row" @submit.prevent="save()">
      <div class="form-group col-6">
        <label for="type">{{ $t('type') }}</label>
        <select name="type" id="type" class="form-control" v-validate="'required'" :state="validateState('type')" v-model="category.type">
          <option value="AV">Avatar</option>
          <option value="BT">{{ $t('bgTournaments') }}</option>
          <option value="BD">{{ $t('bgDivisions') }}</option>
          <option value="BE">{{ $t('bgTeams') }}</option>
          <option value="BU">{{ $t('bgPlayers') }}</option>
          <option value="CA">{{ $tc('card', 2) | toCapitalize }}</option>
        </select>
        <span class="error-inputs">{{ errors.first('type') }}</span>
      </div>
      <div class="form-group col-6">
        <label for="name">{{ $t('name') }}</label>
        <b-form-input name="name" v-validate="'required|max:25'" :state="validateState('name')" v-model="category.name"></b-form-input>
        <span class="error-inputs">{{ errors.first('name') }}</span>
      </div>
      <div class="form-group col-6">
        <label for="file">{{ $t('findImage') }} - <small class="text-muted">*.png | 200x 200x</small></label>
        <div class="custom-file" :class="validateState('files') ? 'is-valid' : 'is-invalid'">
          <input type="file" ref="inputFile" class="custom-file-input" id="file" :lang="lang" @change="imagePreview($event)" v-validate="'image|ext:png,jpg,jpeg|size:1024'" accept=".png,.jpeg,.jpg" name="files" :data-vv-as="$t('image') | toLowerCase">
          <label class="custom-file-label" ref="lblFileName" for="file">{{ $t('findImage') }}</label>
        </div>
        <span class="error-inputs">{{ errors.first('files') }}</span>
        <img v-if="imgPath" :src="imgPath" alt="Imagen de la categoría" class="img-fluid img-thumbnail mt-3">
      </div>
      <div class="form-group col-6">
        <label for="name">{{ $t('order') }}</label>
        <b-form-input type="number" name="order" v-validate="'required|numeric|min:1'" :state="validateState('order')" v-model="category.order"></b-form-input>
        <span class="error-inputs">{{ errors.first('order') }}</span>
      </div>
      <div class="col-12">
        <b-button type="submit" variant="primary" class="mr-2">{{ btnText }}</b-button>
        <b-button @click="$router.go(-1)">{{ $t('cancel') }}</b-button>
      </div>
    </b-form>
  </div>  
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      category: {},
      file: null,
      imgPath: '',
      categoryId: null,
      subCategoryId: null,
      title: null,
      btnText: null
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console'
    ])
  },
  created () {
    this.categoryId = this.$route.params.categoryId
    this.subCategoryId = this.$route.params.subCategoryId
    if (this.categoryId && this.subCategoryId) {
      this.title = this.$t('editSubcategory')
      this.btnText = this.$t('toUpdate')
    } else {
      this.btnText = this.$t('save')
      this.title = this.$t('addSubcategory')
    }
    this.fetchData()
  },
  methods: {
    fetchData () {
      if (this.categoryId && this.subCategoryId) {
        const path = `auth/${this.lang}/console/${this.console}/shop/categories/${this.categoryId}/subcategories/${this.subCategoryId}/edit`
        this.$axios.get(path).then(response => {
          this.category = response.data.data
          this.imgPath = this.category.image
        })
      }
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          let path = null
          if (this.categoryId && this.subCategoryId) {
            path = `auth/${this.lang}/console/${this.console}/shop/categories/${this.categoryId}/subcategories/${this.subCategoryId}/update`
          } else {
            path = `auth/${this.lang}/console/${this.console}/shop/categories/${this.categoryId}/subcategories/store`
          }
          const payload = new FormData();
          payload.append('name', this.category.name);
          payload.append('order', this.category.order);
          payload.append('type', this.category.type);
          if (this.file) {
            payload.append('image', this.file);
          }
          this.$axios.post(path, payload).then(response => {
            this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
            this.$router.go(-1)
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    imagePreview (e) {
      const input = e.target  
      this.file = input.files[0]
      const _self = this
      if (input.files && input.files[0]) {
        _self.$refs.lblFileName.innerHTML = this.file.name
        var reader = new FileReader()
        reader.onload = function (e) {
          _self.imgPath = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
        _self.file = []
        _self.$refs.lblFileName.innerHTML =  _self.$t('findImage')
      }
    }
  }
}
</script>
